<template>
    <div class="items-center w-full px-2 grid grid-cols-2">
        <div class="flex gap-3 items-center">
            <input @change="$emit('tenantAssign', tenant)" v-model="tenant.checked" type="checkbox" :id="tenant.id" class="checkbox cursor-pointer checkbox-primary checkbox-sm flex" />
            <label v-if="tenant.title" :for="tenant.id" class="cursor-pointer text-gray-700 font-semibold truncate">{{ tenant.title }}</label>
            <label v-if="tenant.name" :for="tenant.id" class="cursor-pointer text-gray-700 font-semibold truncate">{{ tenant.name }}</label>

        </div>
        <div class="flex justify-end" v-if="showLicenseAdd">
            <input @keypress="isNumber($event)" :disabled="!tenant.checked" type="number" min="0" step="1" v-model="tenant.license_count" class="w-14 rounded-lg text-center p-1 bg-gray-200 disabled:opacity-50" :class="[!tenant.checked && 'cursor-not-allowed']" />
        </div>
    </div>
</template>

<script>
import { isNumber } from "@/plugins/functions";

export default {
    name: "tenant-list-item",
    components: {},
    props: {
        tenant: {
            type: Object,
            default: () => {},
        },
        showLicenseAdd:{
            type:Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
    methods: {
        isNumber
    },
};
</script>

<style lang="scss" scoped>
    input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
}
</style>
