import axios from "@/axios"

export async function getLicenseList() {
    const url = "/license-pack/tenant/get-license-client-list"
    return await axios.get(url)
}
export async function getLicenseDetails(license_id) {
    const url = `license-pack/tenant/get-license-client-list/${license_id}`
    return await axios.get(url)
}
export async function getFeature() {
    const url = `/feature`
    return await axios.get(url)
}
export async function getAssignedFeature(license_id) {
    const url = `/license-pack/${license_id}/feature`
    return await axios.get(url)
}
export async function getAllClients(tenant_id) {
    const url = `tenant/${tenant_id}/client`
    return await axios.get(url)
}
export async function getAllTenantUsers(tenant_id) {
    const url = `user/tenant/${tenant_id}`
    return await axios.get(url)
}
export async function allotClients(payload) {
    const url = `license-pack/tenant/allocate-client`
    return await axios.post(url, payload)
}
export async function allocateUser(payload) {
    const url = `license-pack/tenant/allocate-user`
    return await axios.post(url, payload)
}
